import React from 'react';
import Slider from 'react-slick';
import './carrousel.scss';
// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

Object.defineProperty(Array.prototype, 'chunk_inefficient', {
  value: function (chunkSize) {
    var array = this;
    return [].concat.apply(
      [],
      array.map(function (elem, i) {
        return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
      })
    );
  },
});

// core components

function CarouselSection(props) {
  const itemsPerSlide = props.itemsPerSlide || 1;
  const imagesHeight = props.imagesHeight || '250px';
  const chunksItems = props.items
    ? props.items.chunk_inefficient(itemsPerSlide)
    : [];
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const settings = {
    slidesToShow: itemsPerSlide,
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToScroll: 1,
    arrows: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          speed: 500,
          autoplaySpeed: 5000,
          autoplay: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          dots: true,
          infinite: true,
          speed: 500,
          autoplaySpeed: 5000,
          autoplay: true,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          infinite: true,
          speed: 500,
          autoplaySpeed: 5000,
          autoplay: true,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === chunksItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? chunksItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div
        key={Math.random().toString(36).substr(2, 9).toString()}
        className='justify-content-center'
        style={{ width: 'auto' }}
      >
        <Slider {...settings}>
          {props.items.map((item, index) => (
            <div
              style={{
                flex: 1,
                position: 'relative',
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
              key={Math.random().toString(36).substr(2, 9).toString()}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <img
                  style={{ objectFit: 'contain', height: imagesHeight }}
                  src={item.img}
                  alt={item.altText}
                  key={item.img + index}
                  loading={'lazy'}
                  className='p-1'
                />
                <div
                  key={Math.random().toString(36).substr(2, 9).toString()}
                  className='carousel-caption d-none d-md-block'
                >
                  <div
                    style={{
                      borderRadius: '5px',
                      boxShadow: '0 30px 40px rgb(0 0 0 / 23%)',
                      backgroundColor: ' rgb(0 0 0 / 33%)',
                    }}
                  >
                    <h5 className='mb-0'>{item.title}</h5>
                    {item.caption ? (
                      <a href={item.redirect} target='_blank' key={index}>
                        <h5 style={{ color: 'white', fontWeight: '500' }}>
                          {item.caption}
                        </h5>
                      </a>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default CarouselSection;
